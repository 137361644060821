<template>
    <v-card class="pa-5">
        <v-card-title class="justify-center">
            <v-img lazy-src="@/assets/modal/warning.svg" src="@/assets/modal/warning.svg" max-width="250" />
        </v-card-title>
        <v-card-text class="text-center">
            <h5 class="text-h5">                        
                {{ $store.state.modal.text }}
            </h5>
            <small v-if="$store.state.modal.caption !== null" class="text-body-2">
                {{ $store.state.modal.caption }}
            </small>
        </v-card-text>
        <v-card-action v-if="$store.state.modal.hasObs">
            <v-textarea
                v-model="obs"
                label="Observação"
                placeholder="Adicione uma observação antes de avançar uma etapa"
                rounded
                outlined
                dense
                hide-details
                auto-grow
            />
        </v-card-action>
        <v-card-actions class="justify-center">
            <v-btn
                color="primary"
                v-text="'Não'"
                rounded
                outlined
                @click="negate"
            />
            <v-btn
                color="primary"
                v-text="'Sim'"
                rounded
                filled
                @click="confirm"
            />
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            obs:null
        }
    },
    methods: {
        negate() {
            this.$store.state.modal.negate()
        },
        confirm() {
            this.$store.state.modal.confirm(this.obs)
        }
    }
}
</script>