<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center">
      <v-img
        lazy-src="@/assets/modal/warning.svg"
        src="@/assets/modal/warning.svg"
        max-width="250"
      />
    </v-card-title>
    <v-card-text class="text-center">
      <h5 class="text-h5">
        {{ $store.state.modal.text }}
      </h5>
      <small
        v-if="$store.state.modal.caption !== null"
        class="text-body-2"
      >
        {{ $store.state.modal.caption }}
      </small>
    </v-card-text>
    <v-card-actions>
      <v-select
        v-model="cancel_description"
        label="Motivo do Cancelamento"
        :items="cancel_reasons"
        :item-value="'name'"
        :item-text="'name'"
        rounded
        dense
        outlined
      />
    </v-card-actions>
    <v-card-actions class="justify-center">
      <v-btn
        color="primary"
        v-text="'Não'"
        rounded
        outlined
        @click="negate"
      />
      <v-btn
        color="primary"
        v-text="'Sim'"
        rounded
        filled
        @click="confirm"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    cancel_reasons: [],
    cancel_description: null,
  }),
  methods: {
    negate() {
      this.$store.state.modal.negate()
    },
    confirm() {
      this.$store.state.modal.confirm(this.cancel_description)
    },
    getCancelReasons() {
      this.$api
        .get(`cancel_reasons`)
        .then((res) => {
          this.cancel_reasons = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.getCancelReasons()
  },
}
</script>
