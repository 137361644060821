<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center">
      <v-img
        lazy-src="@/assets/modal/error.svg"
        src="@/assets/modal/error.svg"
        max-width="250"
      />
    </v-card-title>
    <v-card-text class="text-center">
      <h5 class="text-h5">
        {{ $store.state.modal.text }}
      </h5>
      <small
        v-if="$store.state.modal.caption !== null"
        class="text-body-2"
        v-html="$store.state.modal.caption"
      >
      </small>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        color="primary"
        rounded
        filled
        @click="confirm"
      >
        ok
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    confirm() {
      this.$store.state.modal.confirm()
    },
  },
}
</script>
